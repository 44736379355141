import { DontHydrate, Heading, Link, PageContainer, Processors } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { useAppContext } from '@Contexts/contexts';
import { DesignSystemAsset } from '@UX/components/DesignSystemAsset';
import { Separator } from '@UX/Separator/Separator';

type FooterPaymentsProps = {
  supportedPayments?: string[];
};

export const FooterPayments: React.FC<FooterPaymentsProps> = ({ supportedPayments }) => {
  const { site } = useAppContext();
  const { t } = useTranslation();

  if (!supportedPayments?.length) {
    return null;
  }

  return (
    <DontHydrate
      sx={{
        textAlign: 'center',
      }}
    >
      <PageContainer>
        <Separator
          lineStyle="solid"
          color="strokeLightneutral"
        />

        <Heading
          as="h2"
          variant="smallbold"
          sx={{
            marginTop: 'xl',
            marginBottom: 'xs',
          }}
        >
          {t('supportedPayments')}
        </Heading>

        <Link
          href={site.coreLinks.supportedPayments.url!}
          target="_blank"
          aria-label={site.coreLinks.supportedPayments.label}
        >
          <ul
            sx={{
              minHeight: '135px',
              paddingBottom: 'xl',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              li: {
                verticalAlign: 'middle',
                marginX: 'xs',
              },
            }}
          >
            {supportedPayments?.map((paymentName) => (
              <li key={paymentName}>
                <DesignSystemAsset
                  type="Processor"
                  name={paymentName as Processors}
                  height={['48', '64']}
                />
              </li>
            ))}
          </ul>
        </Link>
      </PageContainer>
    </DontHydrate>
  );
};
